import Immutable from "seamless-immutable";
import MeActionTypes from "./actionTypes";

const initialState = Immutable({});

const MeReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default MeReducer;
