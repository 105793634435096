import BaseActionTypes from "../../base/BaseActionTypes";

const RESOURCE = "me";

const MeActionTypes = {
  ...BaseActionTypes(RESOURCE),
  GET_ME: `${RESOURCE}/GET_ME`,
  GET_COUPONS: `${RESOURCE}/GET_COUPONS`,
  GET_ORDERS: `${RESOURCE}/GET_ORDERS`,
  GET_PURCHASED_ITEMS: `${RESOURCE}/GET_PURCHASED_ITEMS`,
  GET_WALLET: `${RESOURCE}/GET_WALLET`,
  GET_WALLET_HISTORY: `${RESOURCE}/GET_WALLET_HISTORY`,
};

export default MeActionTypes;
