import React, { lazy, Suspense, useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import i18next from "i18next";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ScrollToTop from "../helpers/scroll-top";
import Loading from "../components/Loading";
import actions, { useActions } from "../redux/actions";
import ErrorHandler, { UncaughtRejectionHandler } from "../base/ErrorHandler";

const Home = lazy(() => import("./A01_Home"));
const LoginRegister = lazy(() => import("./B01_LoginRegister"));
const ResetPassword = lazy(() => import("./B02_ResetPassword"));
const ShopGridStandard = lazy(() => import("./C01_ShopGridStandard"));
const Product = lazy(() => import("./C02_Product"));
const BlogDetails = lazy(() => import("./E01_BlogDetails"));
const Blogs = lazy(() => import("./E02_Blogs"));
const AboutUs = lazy(() => import("./G01_AboutUs"));
const Contact = lazy(() => import("./G02_Contact"));
const NotFound = lazy(() => import("./Z01_NotFound"));
const Wishlist = lazy(() => import("./F01_Wishlist"));
const Cart = lazy(() => import("./D01_Cart"));
const Checkout = lazy(() => import("./D02_Checkout"));
const OrderResult = lazy(() => import("./D03_OrderResult"));
const Compare = lazy(() => import("./F02_Compare"));
const MyAccount = lazy(() => import("./B03_MyAccount"));
const Tnc = lazy(() => import("./G05_Tnc"));
const Disclaimer = lazy(() => import("./G03_Disclaimer"));
const Guide = lazy(() => import("./G06_Guide"));
const Policy = lazy(() => import("./G04_Policy"));
const CustomerRedemption = lazy(() => import("./H01_CustomerRedemption"));
const BookingConfirmation = lazy(() => import("./I01_BookingConfirmation"));

function PrivateRoute({
  children,
  roles = ["customer", "guest", "merchant_admin", "admin"],
  ...rest
}) {
  const { t } = useTranslation();
  const cookies = new Cookies();
  const token = cookies.get("token", { path: "/" });
  const role = cookies.get("role", { path: "/" });
  const merchantAdminOnly = roles.length === 1 && roles[0] === "merchant_admin";

  if (!token) {
    if (merchantAdminOnly)
      window.alert(t("bookingConfirmation.please_login_with_merchant_admin"));
    return (
      <Route
        render={({ location }) => {
          const { state } = location || {};
          const { isAllowGuest } = state || {};

          if (isAllowGuest) return <Route {...rest} />;
          return (
            <Redirect
              to={{
                pathname: "/login-register",
                state: { from: location, ...location.state },
              }}
            />
          );
        }}
      />
    );
  }

  if (roles.indexOf(role) === -1) {
    if (merchantAdminOnly)
      window.alert(t("bookingConfirmation.please_login_with_merchant_admin"));
    return (
      <Route
        render={({ location }) => (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location, ...location.state },
            }}
          />
        )}
      />
    );
  }
  return <Route {...rest} />;
}

const App = () => {
  const CategoryActions = useActions(actions.CategoryActions);
  const StoreConfigActions = useActions(actions.StoreConfigActions);
  const CurrencyActions = useActions(actions.CurrencyActions);
  const MeActions = useActions(actions.MeActions);
  const cookies = new Cookies();
  const token = cookies.get("token", { path: "/" });
  const { claimDate } = useSelector((state) => state.me);
  const location = useLocation();
  const setDefaultLanguage = (locale) => {
    const lang = cookies.get("lang", { path: "/" });

    if (lang) return;

    if (!locale.default) {
      return;
    }

    const languageCode = locale.default;
    cookies.set("lang", languageCode, { path: "/" });
    i18next.changeLanguage(languageCode);
  };

  const setDefaultRole = () => {
    const cookies = new Cookies();
    const role = cookies.get("role", { path: "/" });

    if (role) {
      cookies.set("role", role, { path: "/" });
      return;
    }
    cookies.set("role", "guest", { path: "/" });
  };

  // const setDefaultCurrency = (currencyName, exchangeRate) => {
  //   CurrencyActions.setCurrency(currencyName, exchangeRate, currencyName);
  // };

  function setFavicon(icon, logo) {
    const favicon = document.getElementById("favicon");
    favicon.href = icon?.fileUrl || logo?.fileUrl;
  }

  function onChangePath() {
    StoreConfigActions.getList()
      .then((response) => {
        const {
          message: {
            record: { locale, currency, exchangeRate, logo, favicon },
          },
        } = response;
        setDefaultLanguage(locale);
        // setDefaultCurrency(currency.primary, exchangeRate[currency.primary]);

        setFavicon(favicon, logo);
      })
      .catch(ErrorHandler);
    CategoryActions.getList(null, { status: "enabled", level: 0 });
  }

  const setDefaultTier = () => {
    const tier = cookies.get("tier", { path: "/" });
    if (tier) {
      cookies.set("tier", tier, { path: "/" });
    } else {
      cookies.set("tier", "", { path: "/" });
    }
  };

  useEffect(() => {
    // Default API Handler
    window.addEventListener("unhandledrejection", UncaughtRejectionHandler);
    setFavicon();
    setDefaultRole();
    onChangePath();
    setDefaultTier();
    return () =>
      window.removeEventListener(
        "unhandledrejection",
        UncaughtRejectionHandler
      );
  }, []);

  useEffect(() => {
    onChangePath();
  }, [location.pathname]);

  return (
    <ScrollToTop>
      <Suspense fallback={<Loading />}>
        <Switch>
          {/* A00 Home & Main pages */}
          <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />

          {/* B00 Authentication & Accounts */}
          <Route
            path={`${process.env.PUBLIC_URL}/login-register`}
            component={LoginRegister}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/reset-password`}
            component={ResetPassword}
          />
          <PrivateRoute
            path={`${process.env.PUBLIC_URL}/my-account`}
            component={MyAccount}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/logout`}
            render={() => (
              <Redirect
                to={{
                  pathname: "/",
                }}
              />
            )}
          />

          {/* C00 Products */}
          <Route
            path={`${process.env.PUBLIC_URL}/browse/:hashtag`}
            component={ShopGridStandard}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/browse`}
            component={ShopGridStandard}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/product/:id`}
            render={(routeProps) => (
              <Product {...routeProps} key={routeProps.match.params.id} />
            )}
          />

          {/* D00 Cart & Checkout */}
          <Route path={`${process.env.PUBLIC_URL}/cart`} component={Cart} />
          <PrivateRoute
            path={`${process.env.PUBLIC_URL}/checkout`}
            component={Checkout}
            roles={["customer", "guest", "merchant_admin"]}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/order/:orderId/callback`}
            component={OrderResult}
          />

          {/* E00 Blog */}
          <Route
            path={`${process.env.PUBLIC_URL}/blog/:slug`}
            render={(routeProps) => (
              <BlogDetails {...routeProps} key={routeProps.match.params.id} />
            )}
          />
          <Route path={`${process.env.PUBLIC_URL}/blogs`} component={Blogs} />

          {/* F00 Additional User Functions */}
          <Route
            path={`${process.env.PUBLIC_URL}/wishlist`}
            component={Wishlist}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/compare`}
            component={Compare}
          />

          {/* G00 Static Pages */}
          <Route
            path={`${process.env.PUBLIC_URL}/aboutUs`}
            component={AboutUs}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/contact`}
            component={Contact}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/disclaimer`}
            component={Disclaimer}
          />
          <Route path={`${process.env.PUBLIC_URL}/policy`} component={Policy} />
          <Route path={`${process.env.PUBLIC_URL}/tnc`} component={Tnc} />
          <Route path={`${process.env.PUBLIC_URL}/guide`} component={Guide} />

          {/* H00 Staff */}
          <PrivateRoute
            path={`${process.env.PUBLIC_URL}/customer-redemption`}
            component={CustomerRedemption}
          />

          <PrivateRoute
            path={`${process.env.PUBLIC_URL}/booking-confirmation/:id`}
            component={BookingConfirmation}
            roles={["merchant_admin"]}
          />

          {/* Z00 Errors */}
          <Route exact component={NotFound} />
        </Switch>
      </Suspense>
    </ScrollToTop>
  );
};

export default App;
