export default {
  loading: "載入中",
  homepage: "主頁",
  search: "搜尋",
  searchHistory: "搜尋紀錄",
  default: "預設",
  save: "儲存",
  copy: "複製",
  scroll_down: "向下滾動",
  noRecord: "沒有紀錄",
  add: "新增",
  date: "日期",
  delete: "刪除",
  quit: "退出",
  apply: "套用",
  navMenu: {
    login: "登入",
    register: "註冊",
    myAccount: "我的帳戶",
    logout: "登出",
    quantity: "數量",
    sku: "款式",
    option: "選項",
    total: "總計",
    viewCart: "檢視購物車",
    checkout: "結算",
    noItemInCart: "購物車內沒有貨物",
    aboutUs: "關於我們",
    redemption: "商品兌換",
    balance: "Coins 餘額: {{coins}}",
    sessionExpired: "您的使用期限已過，請重新登入。",
  },
  footer: {
    aboutus: "關於我們",
    contact: "聯絡我們",
    usefulLinks: "有用連結",
    disclaimer: "免責聲明",
    policy: "送貨及退貨政策",
    tnc: "使用條款",
    guide: "購物指南",
    subscribeUs: "關注我們",
    Facebook: "Facebook",
    Youtube: "Youtube",
    Instagram: "Instagram",
  },
  home: {
    metaTitle: "Kingsmart",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    buyNow: "立即選購",
    latestProducts: "最新產品",
    latestProductsSubTitle: "送上每月最新產品",
    new: "新",
    selectOptions: "選擇款式",
    addToCart: "新增至購物車",
    addedToCart: "已加到購物車",
    outOfStock: "即將推出",
    view: "檢視",
    addedToWishlist: "已加到最愛",
    addToWishlist: "加到最愛",
    blogTitle: "最新消息",
    writtenBy: "編",
    featureTitle1: "2 FREE SAMPLES",
    featureSubTitle1: "With Every Order",
    featureTitle2: "FREE NEXT DAY DELIVERY",
    featureSubTitle2: "Order by 12 pm. HK Only.",
    featureTitle3: "FREE GIFT WRAP",
    featureSubTitle3: "Select At Checkout",
    bannerTitle1: "",
    bannerTitle2: "",
    viewMoreProducts: "更多產品",
    moreBlogs: "更多文章",
  },
  productList: {
    metaTitle: "Kingsmart",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    allProducts: "所有產品",
    priceLowToHigh: "價錢 - 低至高",
    priceHighToLow: "價錢 - 高至低",
    updateTimeNewToOld: "更新時間 - 新到舊",
    updateTimeOldToNew: "更新時間 - 舊到新",
    categories: "產品種類",
    noCategories: "所有",
    buyNow: "立即選購",
    new: "新",
    selectOptions: "選擇款式",
    addToCart: "新增至購物車",
    addedToCart: "已加到購物車",
    outOfStock: "即將推出",
    view: "檢視",
    addedToWishlist: "已加到最愛",
    addToWishlist: "加到最愛",
    addedToCompare: "已加到比較",
    addToCompare: "加到比較",
    numOfResult: "正顯示 {{total}} 個結果中的 {{current}} 個結果",
    newArrivals: "最新產品",
    brands: "品牌",
    noBrands: "所有",
    point: "積分",
    tierRestriction: "指定會員等級限定",
  },
  productDetails: {
    metaTitle: "Kingsmart | 產品詳情",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    productDetails: "產品詳情",
    sku: "款式",
    option: "選項",
    buyNow: "立即選購",
    addToCart: "加入購物車",
    outOfStock: "即將推出",
    additionalInfo: "更多資料",
    alsoBuy: "你或許也喜歡",
    quantity: "件數",
    packageTotal: "套裝總價",
    relatedProducts: "相關產品",
    alsoLike: "You may also like",
    relatedCategories: "相關產品種類",
    categories: "產品種類",
    packageDetails: "套裝詳情",
    preferredDate: "首選日期",
    preferredTime: "首選時間",
    remark: "備註",
    select: "選擇",
    point: "積分",
    fullName: "姓名",
  },
  loginRegister: {
    metaTitle: "Kingsmart | 登入 / 註冊",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    loginRegister: "登入｜註冊",
    login: "登入",
    register: "註冊",
    email: "電郵地址",
    password: "密碼",
    rememberMe: "記住我",
    forgotPassword: "忘記密碼",
    firstName: "名字",
    lastName: "姓氏",
    phone: "電話",
    agreeToSubscribe: "我同意訂閱電子報",
    loginPanelTitle: "已經是會員?",
    registerPanelTitle: "立即加入!",
    registerPanelSubTitle: "登記成為會員以獲取最新品牌資訊",
    registerModalTitle: "註冊會員",
    resetPasswordMsg:
      "如您所輸入的電郵地址符合我們的系統紀錄，我們將會發送重置密碼之電郵到您的電郵地址。",
    validateAccountMsg: "電郵地址認證成功",
    error_contact_service: "請聯絡客服。",
    error_email_password: "電郵或密碼不正確。",
    error_account_disabled: "你的帳戶已被鎖定。",
    referralCode: "推薦碼",
    gender: "性別",
    birth: "出生日期",
    male: "男",
    female: "女",
    undisclosed: "不公開",
    guestCheckout: "以訪客身份結算",
  },
  myAccount: {
    metaTitle: "Kingsmart | 我的帳戶",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    myAccount: "我的帳戶",
    personalInfo: "個人資料",
    deliveryAddress: "送貨地址",
    orderRecord: "訂單記錄",
    personalInfoTitle: "你的個人詳情",
    firstName: "名字",
    lastName: "姓氏",
    phone: "電話",
    email: "電郵地址",
    address: "地址",
    changePassword: "更改密碼",
    newPassword: "新密碼",
    confirmPassword: "確認新密碼",
    addNewAddress: "新增送貨地址",
    addressLine1: "街道地址",
    addressLine2: "街道地址 2",
    addressLine3: "城市",
    stateProvince: "州 / 省",
    zipCode: "郵遞編號",
    countryRegion: "國家 / 地區",
    order: "訂單",
    orderId: "訂單編號",
    orderStatus: "訂單狀態",
    paymentStatus: "付款狀態",
    deliveryStatus: "送貨狀態",
    total: "總計",
    details: "詳情",
    receiverName: "收件人姓名",
    contactPhone: "聯絡電話",
    image: "圖片",
    productName: "產品名字",
    unitPrice: "單價",
    quantity: "數量",
    subTotal: "小計",
    sku: "款式",
    option: "選項",
    coupons: "優惠券",
    membership: "會員",
    membershipTier: "會員級別",
    amountSpent: "在過去12個月，你已消費 {{amount}}",
    credit: "積分",
    totalCredit: "積分總數",
    expiryDate: "到期日",
    dollars: "{{amount}}",
    date: "日期",
    event: "事件",
    silver: "銀",
    gold: "金",
    discountName: "折扣名稱",
    discountAmount: "折扣金額",
    productDetails: "商品詳情",
    discountDetails: "折扣詳情",
    shippingFee: "運費",
    preferredDate: "預約日期",
    preferredTime: "預約時間",
    remark: "備註",
    status: "狀態",
    redemption: "兌換商品",
    noResults: "沒有結果",
    adjust: "調整",
    pay: "付款",
    topup: "充值",
    rebate: "回贈",
    purchasedContent: "查看已購買內容",
    shippingMethod: "送貨方式",
    trackingNumber: "追蹤號碼",
    pendingCreate: "備貨中",
    shipped: "已發貨",
    inTransit: "運輸中",
    completed: "已完成",
    error: "出錯",
    na: "不適用",
    processing: "處理中",
    confirmed: "已確認",
    cancelled: "已取消",
    paid: "已付款",
    notPaid: "未付款",
  },
  cart: {
    metaTitle: "Kingsmart | 購物車",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    cart: "購物車",
    yourCart: "你的購物車",
    image: "圖片",
    productName: "產品名字",
    unitPrice: "單價",
    quantity: "數量",
    subTotal: "小計",
    sku: "款式",
    option: "選項",
    continueShopping: "繼續購物",
    clearCart: "清空購物車",
    useCoupon: "使用優惠碼",
    inputCouponCode: "輸入優惠碼",
    applyCouponCode: "應用優惠碼",
    cartTotal: "購物車總計",
    totalDiscount: "總折扣",
    total: "總計",
    continueCheckout: "繼續結算",
    noItemInCart: "購物車內沒有貨物",
    buyNow: "立即選購",
    decremented: "商品數量已減少",
    removed: "商品已從購物車中被移除",
    removedAll: "已清空購物車",
    noStock: "商品沒有庫存",
    linkAccount: "連結客戶帳號",
    checkout: "立即結算",
    disabledItems: "已失效的商品",
    removeDisabled: "(結算前請先移除已失效商品)",
    removeAllDisabled: "移除所有已失效商品",
    tierRestriction:
      "購物車內包含非所屬會員級別的產品。失效產品將會從購物車中移除。",
  },
  checkout: {
    metaTitle: "Kingsmart | 結算",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    checkout: "結算",
    billingDetails: "帳單詳情",
    firstName: "名字",
    lastName: "姓氏",
    phone: "電話",
    email: "電郵地址",
    companyName: "公司名稱",
    addressLine1: "街道地址",
    addressLine2: "街道地址 2",
    addressLine3: "城市",
    stateProvince: "州 / 省",
    zipCode: "郵遞編號",
    countryRegion: "國家 / 地區",
    additionalInfo: "額外資訊",
    otherRemarks: "其他備註",
    yourOrder: "您的訂單",
    product: "產品",
    total: "需支付金額",
    delivery: "送貨",
    freeShipping: "免費送貨",
    submitOrder: "提交訂單",
    discount: "折扣",
    totalDiscount: "總折扣",
    noItemInCart: "購物車內沒有貨物",
    buyNow: "立即選購",
    missingInfo: "請填寫完整的帳單資料",
    error: "系統問題。請重試。",
    invalidCode: "折扣碼無效",
    paymentDetails: "付款詳情",
    paymentMethod: "付款方式",
    shippingDetails: "寄件詳情",
    shippingMethod: "寄件方式",
    deliveryDetails: "送貨詳情",
    sameAddress: "帳單地址與送貨地址相同",
    confirmBooking: "請留意電郵通知，職員將盡快確認您的預約",
    insufficientCoin:
      "您的帳戶未有足夠的K coins結餘。如欲繼續以信用卡付款，請按「是」。",
    staffCode: "輸入職員編號",
    apply: "應用",
    tokenError: "二維碼時效已過或已被使用",
    required: "必填",
    optional: "選填",
    redemption: "積分兌換",
    remove: "移除",
    failedToCheckout: "無法為此帳單結算。請再次檢查購物車。",
    wrongStaffCode: "職員編號無效",
    insufficientPoint: "積分不足",
    district: "區域",
    area: "地區",
    locker: "自提點",
    lockerReminder: "如貨品總重量大於5kg，將有人聯絡您安排點到點送貨",
    lockerAddress: "地址",
    lockerOpenHours: "開放時間",
    flat: "單位/室",
    floor: "樓層",
    block: "座數",
    building: "大廈",
    street: "街道",
    remark: "備註",
    tierRestriction: "帳單包含非所屬會員級別的產品。請再次檢查購物車。",
  },
  paymentNotification: {
    metaTitle: "Kingsmart | 付款結果",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    transactionId: "交易編號",
    paymentNotification: "付款結果",
    backToHome: "返回主頁",
    orderSuccessTitle: "成功下單",
    orderSuccessMsg:
      "感謝您的購買！ 我們已收到您的訂單，請檢查您的電子郵件以獲取更多信息。",
    orderCancelledTitle: "訂單取消",
    orderCancelledMsg: "您的訂單已被取消， 請聯繫客戶服務以獲取更多信息。",
    orderRejected: "交易被拒絕，請聯繫你的發卡銀行以獲取更多信息。",
    orderPendingTitle: "待處理訂單",
    orderPendingMsg:
      "感謝您的購買！ 我們已收到您的訂單，請檢查您的電子郵件以獲取更多信息。",
  },
  aboutUs: {
    metaTitle: "Kingsmart",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    aboutUs: "關於我們",
  },
  blog: {
    metaTitle: "Kingsmart",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    relatedProducts: "相關產品",
    relatedCategories: "相關產品種類",
    blogPost: "最新消息",
  },
  allBlog: {
    allBlog: "最新消息",
  },
  blogs: {
    title: "最新消息",
  },
  wishlist: {
    metaTitle: "PinNBuy",
    metaName: "PinNBuy",
    metaContent: "PinNBuy",
    wishlist: "收藏",
    image: "圖片",
    productName: "產品名字",
    unitPrice: "單價",
    selectOptions: "選擇款式",
    addToCart: "新增至購物車",
    addedToCart: "已加到購物車",
    outOfStock: "即將推出",
    continueShopping: "繼續購物",
  },
  compare: {
    metaTitle: "PinNBuy",
    metaName: "PinNBuy",
    metaContent: "PinNBuy",
    compare: "比較",
    product: "產品",
    unitPrice: "單價",
    description: "產品描述",
    sku: "款式",
    option: "選項",
    additionalInfo: "更多資料",
    view: "檢視",
    continueShopping: "繼續購物",
  },
  resetPassword: {
    metaTitle: "PinNBuy",
    metaName: "PinNBuy",
    metaContent: "PinNBuy",
    resetPassword: "重置密碼",
    newPassword: "新密碼",
    confirmPassword: "確認新密碼",
  },
  alerts: {
    accountNotFound: "帳戶不存在",
    wrongEmail: "請提供正確的電郵地址",
    wrongConfirmPassword: "新密碼與確認密碼不符",
    wrongPasswordFormat:
      "請提供正確的密碼，密碼需至少包含8個字元，以數字及英文字母組成 (包括1個大階字母)",
    wrongPhoneFormat: "請提供正確的手提號碼",
    fillNecessaryInfo: "請填寫全部資料",
    updateAccountInfoSuccess: "資料已被更改",
    updatePasswordSuccess: "密碼已被更改",
    referencePriceRemarks: "參考價錢",
    loginFirst: "請先登入",
  },
  qrCodeScanner: {
    scan: "掃描二維碼",
    enableAccess: "請檢查相機權限",
    or: "或",
    inputMobile: "輸入客戶電話號碼",
    submit: "提交",
  },
  customerRedemption: {
    metaTitle: "Kingsmart | 商品兌換",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    bookingItems: "預約服務",
    image: "相片",
    productDetails: "商品詳情",
    unitPrice: "單價",
    staffCode: "職員編號",
    redeem: "兌換",
    invalidCode: "二維碼無效",
    enterMobile: "請輸入客戶電話號碼",
    redeemed: "已兌換",
    enterCode: "請輸入職員編號",
    redeemedAt: "換領日期",
    expiredAt: "到期日",
    cancelledAt: "取消日期",
    available: "可兌換",
    expired: "已逾期",
    cancelled: "已取消",
    noResults: "沒有結果",
  },
  bookingConfirmation: {
    metaTitle: "Kingsmart | 確認預約",
    metaName: "Kingsmart",
    metaContent: "Kingsmart",
    bookingItems: "預約商品",
    orderId: "訂單編號",
    transactionId: "交易編號",
    image: "圖片",
    productDetails: "商品詳情",
    unitPrice: "單價",
    staffCode: "職員編號",
    redeem: "兌換",
    redeemed: "已兌換",
    enterCode: "請輸入職員編號",
    preferredDate: "預約日期",
    preferredTime: "預約時間",
    remark: "備註",
    submit: "提交",
    book_success: "已更新",
    pending: "待換領",
    revoked: "已取消",
    expired: "已逾期",
    please_login_with_merchant_admin: "請登入至商戶管理員帳戶。",
  },
  coupon: {
    active: "有效",
    event: "活動",
    code: "優惠碼",
    purchase_reached: "需消費滿",
    expiry_date: "有效日期",
    redeemed_expired: "已換領 / 已過期",
  },
};
